.logo {
  height: var(--height-header-logo);
}

.headerLogo {
  height: var(--height-header-logo);
}

.footerLogo {
  height: var(--height-footer-logo);
}
