.section {
  display: flex;
  flex-direction: column;
  background-color: var(--clr-background-main);
  align-items: center;
  overflow: hidden;
  min-height: var(--min-height-section);
}

.secondary {
  background-color: var(--clr-background-secondary);
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  padding: var(--padding-v-section) var(--padding-h-section);
  width: var(--width-section);
  box-sizing: border-box;
  align-items: var(--align-section);
  text-align: var(--align-section);
  gap: var(--spacing-section);
}

.centered {
  align-items: center;
  text-align: center;
}

.fullWidth {
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 0;
}

.noPadding {
  padding: 0;
}
