.header {
  display: flex;
  flex-direction: column;
  background-color: var(--clr-header-background);
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--clr-header-underline);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: var(--height-header);
}

.headerContainer {
  padding: var(--padding-v-header) var(--padding-h-header);
  width: var(--width-header);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--height-header);
}
