.footer {
  display: flex;
  flex-direction: column;
  background-color: var(--clr-footer-background);
  align-items: center;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--clr-footer-overline);
}
 
.footerContainer {
  padding: var(--padding-v-footer) var(--padding-h-footer);
  width: var(--width-footer);
  box-sizing: border-box;
}