.expandableChip {
  background-color: var(--clr-background-secondary);
  padding: var(--padding-v-chip) var(--padding-h-chip);
  border-radius: var(--border-radius-tile);
  cursor: pointer;
}

.expanded {
  background-color: var(--clr-background-tertiary);
}
