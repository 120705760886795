.tileContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-tile);
}

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: var(--clr-background-secondary);
  border-radius: var(--border-radius-tile);
  height: var(--height-tile);
  padding: var(--padding-v-tile) var(--padding-h-tile);
  box-sizing: border-box;
  width: var(--width-tile-half);
}

.secondary {
  background-color: var(--clr-background-main);
}
