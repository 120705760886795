.free {
  background-color: var(--clr-background-free);
}

.standard {
  background-color: var(--clr-background-standard);
}

.premium {
  background-color: var(--clr-background-premium);
}
