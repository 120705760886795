.icon {
  --icon-color: var(--clr-neutral-500);
  --icon-color-hover: var(--clr-neutral-700);
}

.icon path {
  fill: var(--icon-color);
}

.icon path.hover {
  fill: var(--icon-color-hover);
}

.icon path.emphasized {
  fill: var(--clr-blue-700);
}

.icon path.emphasized.hover {
  fill: var(--clr-blue-900);
}

.icon path.warning {
  fill: var(--clr-red-700);
}

.icon path.warning.hover {
  fill: var(--clr-red-900);
}

.icon path.important,
.icon.important path,
.icon path.alert,
.icon.alert path {
  fill: white;
}

.icon path.important.hover {
  fill: white;
}

.icon path.red {
  fill: var(--clr-red-darker);
}

.icon path.blue {
  fill: var(--clr-blue-700);
}
