.navLinkList {
  display: var(--desktop-display);
  height: 100%;
  flex-grow: 1;
  justify-content: center;
  padding: 0;
  margin: 0 var(--spacing-h-navlinks);
}

.navLinkItem {
  display: flex;
  height: 100%;
  list-style-type: none;
}

.navLinkItem > a {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 var(--spacing-h-navlinks);
  font-size: var(--font-size-button);
}

.navLinkItem > a:hover {
  color: var(--clr-call-to-action-hover);
}
