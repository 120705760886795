.featureCard {
  display: flex;
  flex-direction: var(--card-flex-direction);
}
.contentOnTop {
  flex-direction: var(--card-flex-direction-content-top);
}
.contentOnRight {
  flex-direction: var(--card-flex-direction-content-right);
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: var(--min-height-feature-card);
}

.description {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 48px;
}

.content.splitOneTwo {
  flex: 2;
}
