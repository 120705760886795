.paragraph {
  margin: 0;
}

.billboardParagraph {
  font-size: var(--font-size-billboard-paragraph);
}

.cardParagraph {
  font-size: var(--font-size-card-paragraph);
}

.secondary {
  color: var(--clr-text-secondary);
}

.titledList,
.list {
  margin: 8px 0;
}

.chip {
  color: var(--clr-text-secondary);
  margin: 0;
  margin-top: 16px;
}

.footer {
  font-size: var(--font-size-footnotes);
}
