.textLink {
  cursor: pointer;
  font-size: var(--font-size-base);
  text-decoration: none;
  font-weight: var(--font-weight-text-link);
  color: var(--clr-call-to-action);
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.textLink:hover {
  text-decoration: underline;
  color: var(--clr-call-to-action-hover);
}

.mobileNav {
  font-size: var(--font-size-header5);
  color: var(--clr-text-main);
}
.mobileNav:hover {
  text-decoration: none;
}

.inline {
  display: inline-block;
}

.footer {
  font-size: var(--font-size-footnotes);
  color: var(--clr-text-main);
  text-decoration: underline;
}
