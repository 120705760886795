.mobileNavLinksContainer {
  display: var(--mobile-display);
}

.navDropDown {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: var(--height-header);
  background-color: var(--clr-header-background);
  padding: var(--padding-mobile-nav);
  gap: var(--spacing-list-items);
  height: unset;
  border-bottom: 1px solid var(--clr-header-underline);
}

.screener {
  position: fixed;
  left: 0;
  right: 0;
  top: var(--height-header);
  bottom: 0;
  background-color: var(--clr-background-tertiary);
  opacity: 0.75;
}

.hidden {
  display: none;
  height: 0;
}
