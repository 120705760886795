.noMargin,
.titledList,
.chip {
  margin: 0;
}

.normalWeight,
.chip {
  font-weight: var(--font-weight-paragraph);
}

.emphasized {
  font-weight: var(--font-weight-header);
}
