:root {
  --font-weight-header: 600;
  --font-weight-paragraph: 400;
  --font-weight-text-link: var(--font-weight-paragraph);
  --font-size-base: 17px;
  --font-size-button: 15px;
  --font-size-subcopy-base: 19px;
  --font-size-large: var(--font-size-base);
  --font-size-footnotes: 12px;
  --font-size-header1: 80px;
  --font-size-header2: 64px;
  --font-size-header3: 48px;
  --font-size-header4: 32px;
  --font-size-header5: 24px;
  --font-size-header6: var(--font-size-subcopy-base);
  --font-size-subcopy1: 24px;
  --font-size-subcopy2: var(--font-size-subcopy-base);
  --font-size-subcopy3: var(--font-size-subcopy-base);
  --font-size-subcopy4: var(--font-size-subcopy-base);
  --font-size-subcopy5: var(--font-size-base);
  --font-size-subcopy6: var(--font-size-base);
  --font-size-billboard-paragraph: var(--font-size-subcopy-base);
  --font-size-card-paragraph: var(--font-size-base);
  --line-height-base: 24px;
  --line-height-header1: 88px;
  --clr-neutral-50: #f8f7f7;
  --clr-neutral-100: #f3f2f2;
  --clr-neutral-200: #e7e4e4;
  --clr-neutral-300: #ceccca;
  --clr-neutral-500: #737f8c;
  --clr-neutral-700: #5c6670;
  --clr-neutral-900: #2e3338;
  --clr-green-100: #e6ebe0;
  --clr-green-200: #c8d4b7;
  --clr-green-700: #859a5d;
  --clr-green-900: #4d5734;
  --clr-blue-100: #ecf3f9;
  --clr-blue-200: #c5daec;
  --clr-blue-700: #346da0;
  --clr-blue-900: #1d3d59;
  --clr-red-100: #fceae9;
  --clr-red-200: #f4c1bd;
  --clr-red-700: #9f332b;
  --clr-red-900: #5e1d18;
  --clr-yellow-100: #fbf0d0;
  --clr-yellow-200: #f5da8a;
  --clr-yellow-700: #c49811;
  --clr-yellow-900: #84650b;
  --clr-text-main: #2e3338;
  --clr-text-secondary: #5c6670;
  --clr-background-main: #ffffff;
  --clr-background-secondary: #f8f7f7;
  --clr-background-tertiary: #f3f2f2;
  --clr-header-background: var(--clr-background-main);
  --clr-header-underline: #e7e4e4;
  --clr-footer-background: var(--clr-background-tertiary);
  --clr-footer-overline: transparent;
  --clr-separator: rgba(131, 127, 124, 0.2); /* #e7e4e4; */
  --clr-button-main: #e7e4e4;
  --clr-button-main-hover: #ceccca;
  --clr-call-to-action: #3d81bc;
  --clr-call-to-action-text: var(--clr-background-main);
  --clr-call-to-action-hover: #346da0;
  --clr-call-to-action-text-hover: var(--clr-background-main);
  --clr-background-free: var(--clr-neutral-200);
  --clr-background-standard: var(--clr-blue-200);
  --clr-background-premium: var(--clr-yellow-200);
  --padding-v-section: 24px;
  --padding-h-section: 24px;
  --spacing-section: 28px;
  --padding-v-button: 8px;
  --padding-h-button: 24px;
  --padding-v-button-large: 12px;
  --padding-h-button-large: 32px;
  --padding-v-header: 0;
  --padding-h-header: var(--padding-h-section);
  --padding-mobile-nav: var(--padding-v-section);
  --spacing-h-navlinks: 24px;
  --padding-v-footer: 32px;
  --padding-h-footer: var(--padding-h-header);
  --spacing-list-items: 16px;
  --padding-h-list: 48px;
  --margin-v-separator: var(--padding-v-section);
  --height-header: 48px;
  --height-header-logo: calc(var(--height-header) - 28px);
  --height-footer-logo: var(--height-header-logo);
  --min-height-section: 440px;
  --min-height-feature-card: 400px;
  --width-body: initial;
  --width-section: 100%;
  --width-header: var(--width-section);
  --width-footer: var(--width-section);
  --height-tile: 350px;
  --spacing-tile: var(--spacing-list-items);
  --padding-v-tile: 32px;
  --padding-h-tile: var(--padding-h-list);
  --width-tile-half: calc(var(--width-section) / 2 - var(--spacing-tile) / 2);
  --width-tile-third: calc(
    var(--width-section) / 3 - var(--spacing-tile) * 2 / 3
  );
  --width-tile-two-thirds: calc(
    var(--width-tile-third) * 2 + var(--spacing-tile)
  );
  --padding-v-chip: var(--padding-v-tile);
  --padding-h-chip: var(--padding-h-list);
  --width-minimal: 320px;
  --width-tablet: 768px;
  --width-desktop: 1200px;
  --border-radius-button: 4px;
  --border-radius-tile: 16px;
  --border-radius-chip: var(--border-radius-tile);
  --align-section: initial;
  --desktop-display: flex;
  --mobile-display: none;
  --card-flex-direction: row-reverse;
  --card-flex-direction-content-top: row-reverse;
  --card-flex-direction-content-right: row;
}

/*
@media (max-width: 767px): Typically used for smartphones and small mobile devices.
@media (min-width: 768px): Often considered as the starting point for tablets and larger 
@media (min-width: 768px): Suitable for most tablets and smaller desktop screens
@media (min-width: 992px): Commonly used for larger tablets and medium-sized desktop screens.
@media (min-width: 1200px): Often used for larger desktop screens.
@media (min-resolution: 2dppx): Targets devices with a pixel density of 2 or more, such as Retina displays.
*/

body {
  margin: 0;
  padding: 0;
  background-color: var(--clr-background-main);
  min-width: var(--width-body);
  padding-top: var(--height-header);
}
* {
  font-family: "Source Sans Pro", sans-serif;
  color: var(--clr-text-main);
  font-weight: var(--font-weight-paragraph);
  font-size: var(--font-size-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-header);
  margin-bottom: 0;
}
h1 {
  font-size: var(--font-size-header1);
  line-height: var(--line-height-header1);
  /* margin-bottom: calc(var(--line-height-header1) / 2); */
}
h2 {
  font-size: var(--font-size-header2);
}
h3 {
  font-size: var(--font-size-header3);
}
h4 {
  font-size: var(--font-size-header4);
}
h5 {
  font-size: var(--font-size-header5);
}
h6 {
  font-size: var(--font-size-header6);
}

@media (min-width: 1200px) {
  :root {
    --width-section: var(--width-desktop);
    --width-tile-half: calc(
      var(--width-section) / 2 - var(--padding-h-section) - var(--spacing-tile) /
        2
    );
    --width-tile-third: calc(
      var(--width-section) / 3 - var(--padding-h-section) * 2 / 3 -
        var(--spacing-tile) * 2 / 3
    );
    --height-tile: 300px;
  }
}
@media (max-width: 992px) {
  :root {
    --height-tile: 400px;
    --width-tile-third: calc(
      var(--width-section) / 2 - var(--spacing-tile) / 2
    );
    --width-tile-two-thirds: var(--width-section);
  }
}
@media (max-width: 768px) {
  :root {
    --width-section: 100%;
    --width-tile-half: var(--width-section);
    --width-tile-third: var(--width-section);
    --width-tile-two-thirds: var(--width-section);
    --align-section: center;
    --desktop-display: none;
    --mobile-display: flex;
    --card-flex-direction: column;
    --card-flex-direction-content-top: column-reverse;
    --card-flex-direction-content-right: column;
    --height-tile: 300px;
  }
}
@media (max-width: 560px) {
  :root {
    --height-tile: 350px;
  }
}
@media (max-width: 440px) {
  :root {
    --height-tile: 400px;
  }
}
@media (max-width: 320px) {
  :root {
    --width-body: var(--width-minimal);
  }
}
