.card {
  display: flex;
  flex-direction: column;
  align-items: var(--align-section);
  padding: var(--padding-v-tile) var(--padding-h-tile);
  border-radius: var(--border-radius-tile);
  width: 100%;
  box-sizing: border-box;
}

.oneHalf {
  width: var(--width-tile-half);
}

.oneThird {
  width: var(--width-tile-third);
}

.twoThirds {
  width: var(--width-tile-two-thirds);
}

.noVerticalPadding {
  padding-top: 0;
  padding-bottom: 0;
}
