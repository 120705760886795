.image {
  object-fit: contain;
}

.featureCard {
  width: 100%;
  height: calc(var(--min-height-feature-card) - 64px);
}

.fill {
  object-fit: cover;
}
