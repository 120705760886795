.list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-list-items);
  box-sizing: border-box;
  margin: auto;
}

.titledList,
.expandableList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-list-items);
  list-style-type: none;
  padding: 0 var(--padding-h-list);
}

.expandableList {
  padding: 0;
}

.secondary,
.secondary > * {
  color: var(--clr-text-secondary);
}

.oneHalf {
  width: var(--width-tile-half);
}

.oneThird {
  width: var(--width-tile-third);
}

.twoThirds {
  width: var(--width-tile-two-thirds);
}

.checkMarks {
  list-style-type: "✔ ";
}
